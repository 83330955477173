import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AutoDestroyable } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { ThemeService } from '@cohesity/helix';
import { helpUrls } from '@cohesity/iris-shared-constants';

/**
 * This component provides the Veritas merger information.
 */
@Component({
  standalone: true,
  selector: 'coh-veritas-info',
  templateUrl: './veritas-info.component.html',
  styleUrls: ['./veritas-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    MatButtonModule,
    TranslateModule,
  ],
})
export class VeritasInfoComponent extends AutoDestroyable {
  /**
   * The URL to Vertas detail.
   */
  detailUrl = helpUrls.veritasDetail;

  /**
   * The URL to the welcome video for Veritas.
   */
  welcomeUrl = helpUrls.veritasWelcomeVideo;

  /**
   * True if is light theme.
   */
  isLightTheme = false;

  constructor(
    private themeService: ThemeService,
  ) {
    super();
    this.themeService.currentTheme$.pipe(
      this.untilDestroy(),
    ).subscribe(
      theme => this.isLightTheme = theme.mode === 'light'
    );
  }
}
