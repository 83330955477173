import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import { AjaxHandlerService, AutoDestroyable, PersistService } from '@cohesity/utils';
import { TranslateModule } from '@ngx-translate/core';
import { M365SelfServicePortalModule } from '@cohesity/adaptors/office365/dashboard';
import { flagEnabled, IrisContextService, isIbmBaaSEnabled, isMcmGovCloud } from '@cohesity/iris-core';

import { LoginComponent, VeritasInfoComponent } from '../../components';
import { AuthType, LoginCredentials } from '../../models';
import { LoginData } from '../../services';

/**
 * Veritas info display date. Dec 10, 2024, 12am Pacific Time.
 */
const showVeritasInfoTime = new Date('2024-12-10T00:00:00-08:00').getTime();

/**
 * Translation string keys for the title block and copyright.
 */
export interface LoginContent {
  title?: string;
  description?: string;
  description2?: string;
  illustrationUrl?: string;
  poweredBy?: string;
  companyName?: string;
}

/**
 * Default translation string keys for the title block and copyright.
 */
export const defaultLoginContent: LoginContent = {
  title: 'welcome',
  description: 'toCohesityDataCloud',
  description2: 'login.helios.marketingBlurb',
  illustrationUrl: '/assets/i/cohesity-data-cloud-login.svg',
  poweredBy: 'poweredByHelios',
  companyName: 'cohesityInc',
};

/**
 * Translation string keys (updated with AI-powered text) for the title block and copyright.
 */
export const aiLoginContent: LoginContent = {
  ...defaultLoginContent,
  title: 'theCohesityDataCloud',
  description: 'login.helios.aiMarketingBlurb',
  description2: null,
};

/**
 * This component provides the main layout for the login page.
 * Most of the properties are rendered with defaults for our standard
 * login ux.  But they can be optionally overriden.
 *
 * <coh-login-page
 *  [content]="loginContent"
 *  [domains]="domains"
 *  [availableAuthModes]="availableAuthModes"
 *  [authFunction]="authenticate"
 *  (authSuccess)="handleLoginSuccess($event)"
 * ></coh-login-page>
 *
 */
@Component({
  standalone: true,
  selector: 'coh-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    LoginComponent,
    CommonModule,
    M365SelfServicePortalModule,
    TranslateModule,
    VeritasInfoComponent,
  ],
})
export class LoginPageComponent extends AutoDestroyable implements AfterViewInit, OnDestroy {
  /**
   * True if showing Veritas info.
   */
  veritasInfoEnabled = flagEnabled(this.ctx.irisContext, 'loginPageWithVeritasInfo') &&
    !isIbmBaaSEnabled(this.ctx.irisContext) &&
    !isMcmGovCloud(this.ctx.irisContext) &&
    (new Date().getTime() >= showVeritasInfoTime);

  /**
   * The domains to be displayed in the login form.
   * This is specifically for active directory.
   */
  @Input()
  get domains() {
    return this._domains;
  }

  set domains(value: string[]) {
    this._domains = value && value.length ? value : this._domains;
  }

  _domains: string[] = [];

  /**
   * The authentication function to call when credentials are submitted.
   * This auth function should take the login credentials type as an argument
   * and return a promise.
   */
  @Input()
  authFunction: (loginCredentials: LoginCredentials, authType: AuthType) => Promise<LoginData>;


  /**
   * The title Information of the login page.
   */
  _content: LoginContent = this.veritasInfoEnabled ? aiLoginContent : defaultLoginContent;

  /**
   * Any content provided by parent component will override default.
   * If overrides are not provided, default will be used.
   */
  @Input() set content(content: LoginContent) {
    if (content) {
      this._content = {
        ...defaultLoginContent,
        ...content,
      };
    }
  }

  get content(): LoginContent {
    return this._content;
  }

  /**
   * These are the available auth modes for the appliance form factor.
   * These are passed into the login component to configure it correctly.
   */
  @Input()
  get availableAuthModes() {
    return this._availableAuthModes;
  }

  set availableAuthModes(authModes) {
    if (authModes && authModes.length) {
      this._availableAuthModes = authModes;
      this.configureInitialAuthMode(this._availableAuthModes);
    }
  }

  _availableAuthModes: AuthType[] = ['local', 'sso', 'ad'];


  /**
   * The authMode to render the login form with initialially.
   * This can change based on the available auth modes and
   * we also attempt to store the last used auth mode and return
   * the user to that auth mode first.
   */
  initialAuthMode = 'local';


  /**
   * The last used auth mode of this user, stored in local storage
   * and retrieved on component initialization.
   */
  lastUsedAuthMode: AuthType;

  /**
   * Whether to display the office 365 self service recovery component.
   */
  @Input()
  displayM365SelfServiceRecovery;

  /**
   * Notification of successful login.
   */
  @Output()
  authSuccess = new EventEmitter<LoginData>();

  /**
   * Notification of failed login.
   */
  @Output()
  authFailure = new EventEmitter<string>();


  /**
   * This is the default auth mode for the component.
   */
  currentAuthMode: AuthType = 'local';

  /**
   * The year to display in the copyright text.
   */
  copyrightYear = new Date().getFullYear();

  /**
   * Indicates when login is either rendering for the first time
   * or a successful login and state is transitioning to home page.
   */
  private transitioning = true;

  /**
   * The timer to start the animation after rendering.
   */
  private timer;

  /**
   * Controls the class that applies the fade in animation.
   */
  @HostBinding('class.fade-in')
  get isNotTransitioning(): boolean {
    return this.transitioning === false;
  }

  /**
   * Controls the class that applies the fade out animation.
   */
  @HostBinding('class.fade-out')
  get isTransitioning(): boolean {
    return this.transitioning === true;
  }

  /**
   * The key used to set and get the value from
   * persist service to restore ui to previously used auth mode.
   */
  localStorageKey = 'lastUsedAuthMode';

  constructor(
    private persistService: PersistService,
    private ajaxHandlerService: AjaxHandlerService,
    private cdr: ChangeDetectorRef,
    private ctx: IrisContextService,
  ) {
    super();
    // attempt to restore ui into the last used auth mode for the user.
    this.lastUsedAuthMode = persistService.get(this.localStorageKey);
    this.configureInitialAuthMode(this._availableAuthModes);
  }

  /**
   * This method is called after the component's view has been initialized.
   * It sets a timeout to set the transitioning state to false.
   */
  ngAfterViewInit(): void {
    this.timer = setTimeout(() => {
      this.transitioning = false;
    }, 500);
  }

  /**
   * This method is called when the component is destroyed.
   * It clears the timeout and cancels any subscriptions
   * tracked in the parent.
   */
  ngOnDestroy(): void {
    super.ngOnDestroy();
    clearTimeout(this.timer);
  }

  private configureInitialAuthMode(availableAuthModes: AuthType[]) {
    // make sure that the persisted auth mode is also in the available auth modes.
    this.initialAuthMode = (this.lastUsedAuthMode && availableAuthModes.includes(this.lastUsedAuthMode)) ?
      this.lastUsedAuthMode : availableAuthModes[0];

    // re-render
    this.cdr.markForCheck();
  }

  /**
   * Once user is authenticated, this method
   * will forward them to the home page.
   */
  handleLoginSuccess(loginData) {
    /**
     * If forceChangePassword or Mfa is enabled,
     * then user service will return null for login data.
     *
     * This means that additional process is required and
     * UserService is responsible for handling this
     * and is transitioning user to the change password page
     * or mfa setup page.
     */
    if (!loginData) {
      return;
    }

    this.transitioning = true;
    this.persistService.set(this.localStorageKey, this.currentAuthMode);

    // @todo For helios login, need to call ClusterService.getClusterInfoWithLocale()
    this.authSuccess.emit(loginData);
  }

  /**
   * User failed authentication.
   */
  handleLoginFailure(error) {
    this.transitioning = false;
    this.ajaxHandlerService.handler(error);
    this.authFailure.emit(error);
  }
}
