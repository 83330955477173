<div class="login-page" [class.login-with-marketing-info]="veritasInfoEnabled">
  <section class="login-form-section">
    <!-- <c-spinner ng-if="$ctrl.checkingValidCertificate"></c-spinner> -->
    <div class="login-form-section-container">
      <h1 class="login-title text-center">{{ content.title | translate }}</h1>
      <h2 class="login-description text-center">{{ content.description | translate }}</h2>
      <h4 class="login-description2 text-center">{{ content.description2 | translate }}</h4>
      <div class="helios-powered-by text-center">{{ content.poweredBy | translate }}</div>
      <coh-login
        [domains]="domains"
        [authModes]="availableAuthModes"
        [initialAuthMode]="initialAuthMode"
        (authSuccess)="handleLoginSuccess($event)"
        (authFailure)="handleLoginFailure($event)"
        [authFunction]="authFunction"
      ></coh-login>
      <coh-m365-msft-login-redirect
        *ngIf="displayM365SelfServiceRecovery">
      </coh-m365-msft-login-redirect>
    </div>
  </section>
  <section *ngIf="!veritasInfoEnabled" class="hero-graphic">
    <img
      class="helios-logo"
      alt="{{ content.title }} Image"
      [src]="content.illustrationUrl"
      draggable="false" />
  </section>
  <section *ngIf="veritasInfoEnabled" class="marketing-graphic">
    <coh-veritas-info></coh-veritas-info>
  </section>
</div>
<div class="login-footer">
    {{ 'copyrightNg' | translate : { year: copyrightYear, company: content.companyName | translate } }}
</div>
