export * from './config/index';
export * from './entitlement';

import { Type } from '@angular/core';
import {
  BaseBannerConfig,
  DataHawkClassificationEntitlementBanner,
  DataHawkThreatProtectionEntitlementBanner,
  FortKnoxAzureEntitlementBanner,
  FortKnoxColdEntitlementBanner,
  RansomwareInfoEntitlementBanner
} from '@cohesity/iris-core';

import {
  ClusterCertificateExpiryBannerConfig,
  ClusterUpgradeInProgressBannerConfig,
  ClusterUpgradesBannerConfig,
  DiskRemovalBannerConfig,
  EnableMfaBannerConfig,
  HeliosMfaBannerConfig,
  RpaasQuorumBannerConfig,
  SuperAdminInformationBannerConfig,
  SupportUserMfaBannerConfig,
  CertificateSettingsBannerConfig,
  ClusterAgentCertBannerConfig,
  OneHeliosSetupBannerConfig,
} from './config';
import { DataProtectAzureEntitlementBanner, DataProtectEntitlementBanner } from './entitlement';

export const customBannerConfigs: Type<BaseBannerConfig>[] = [
  ClusterCertificateExpiryBannerConfig,
  ClusterUpgradeInProgressBannerConfig,
  ClusterUpgradesBannerConfig,
  DataHawkClassificationEntitlementBanner,
  DataHawkThreatProtectionEntitlementBanner,
  DataProtectAzureEntitlementBanner,
  DataProtectEntitlementBanner,
  DiskRemovalBannerConfig,
  EnableMfaBannerConfig,
  FortKnoxAzureEntitlementBanner,
  FortKnoxColdEntitlementBanner,
  HeliosMfaBannerConfig,
  RansomwareInfoEntitlementBanner,
  RpaasQuorumBannerConfig,
  SuperAdminInformationBannerConfig,
  CertificateSettingsBannerConfig,
  SupportUserMfaBannerConfig,
  ClusterAgentCertBannerConfig,
  OneHeliosSetupBannerConfig,
];
